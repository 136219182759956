// Import React
// Import GraphQL
import { graphql } from 'gatsby';
// Import the GatsbySEO plugin
import { GatsbySeo } from 'gatsby-plugin-next-seo';
// Import the Gatsby Prismic plugin
import { withPreview } from 'gatsby-source-prismic';
import React, { Fragment } from 'react';
import {
  Associations,
  BenefitList,
  Breadcrumb,
  CallToAction,
  HomeHero,
  HomeSwitch,
  Navbar,
  PageFooter,
  PageHero,
  ServicesList
} from '../components';
import PebaTalk from '../components/PebaTalk';
import ScriptHelemt from '../scripts/helemt';
// Import our styles
import '../style/style.scss';

// Export PageQuery
export const query = graphql`
  query PageQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      id
      uid
      data {
        body {
          ... on PrismicPageBodyPageHero {
            id
            slice_type
            slice_label
            primary {
              h_accent_color
              ph_bg_overlay
              ph_headline
              ph_text
              ph_bg_image {
                alt
                thumbnails
                url
                copyright
              }
            }
          }
          ... on PrismicPageBodyParticipatingAssociations {
            id
            slice_label
            slice_type
            primary {
              pa_divider_color
              pa_headline
              pa_items_per_row
              pa_body_text {
                raw
              }
            }
            items {
              pa_association_name
              pa_association_logo {
                alt
                copyright
                thumbnails
                url
              }
            }
          }
          ... on PrismicPageBodyWhatWeDo {
            id
            slice_label
            slice_type
            primary {
              wwd_headline
              wwd_per_row
            }
            items {
              wwd_block_heading
              wwd_block_body {
                raw
              }
            }
          }
          ... on PrismicPageBodyBuilder {
            id
            slice_label
            slice_type
            primary {
              builder_section_accent
              builder_section_accent_color
              builder_section_bg
              builder_section_color
            }
            items {
              builder_column_ph_width
              builder_column_tab_width
              builder_column_web_width
              uilder_column_fw_image
              builder_column_content {
                raw
              }
            }
          }
          ... on PrismicPageBodyCallToAction {
            id
            slice_label
            slice_type
            primary {
              cta_bg_overlay
              cta_form_button_text
              cta_form_getform
              cta_form_headline
              cta_form_text
              cta_headline
              cta_preheadline
              cta_text
              cta_bg_image {
                alt
                copyright
                thumbnails
                url
              }
            }
          }
          ... on PrismicPageBodyHomeHero {
            id
            slice_label
            slice_type
            primary {
              home_hero_heading
              home_hero_preheading
              home_hero_image {
                url
              }
            }
          }
          ... on PrismicPageBodyBenefitCardGrid {
            id
            slice_label
            slice_type
            primary {
              bcg_grid_columns
              bcg_grid_filter
              bcg_grid_paginate
              bcg_grid_per_page
              cg_heading
            }
            items {
              bcg_grid_item {
                uid
                document {
                  ... on PrismicBenefit {
                    id
                    data {
                      benefit_name
                      benefit_list_image {
                        url
                      }
                      body {
                        ... on PrismicBenefitBodyBenefitBodyText {
                          id
                          primary {
                            benefit_body_text_content {
                              raw
                            }
                          }
                          slice_type
                        }
                      }
                      benefit_type
                      benefit_provider {
                        uid
                        document {
                          ... on PrismicProviders {
                            id
                            data {
                              provider_name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        page_parent {
          id
        }
        seo_title
        seo_description
        seo_noindex
        seo_nofollow
      }
    }

    allPrismicPost(
      sort: { fields: [data___post_date], order: ASC }
      filter: {
        data: {
          post_categories: {
            elemMatch: { post_category: { uid: { eq: "peba-talk" } } }
          }
        }
      }
    ) {
      edges {
        node {
          id
          uid
          data {
            post_categories {
              post_category {
                uid
              }
            }
            post_date
            post_title
            post_seo_title
            post_seo_description
            post_seo_robots
            post_append_site_title
            body {
              ... on PrismicPostBodyTextBlock {
                id
                slice_label
                slice_type
                primary {
                  post_text_content {
                    raw
                  }
                }
              }
              ... on PrismicPostBodyImageBlock {
                id
                slice_label
                slice_type
                primary {
                  post_image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Sort and display the different slice options
const PageSlices = ({ slices, uid }) =>
  slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'page_hero':
          return (
            <>
              <PageHero slice={slice} />
              <Breadcrumb current={uid} />
            </>
          );
        case 'builder':
          return <HomeSwitch slice={slice} />;

        case 'participating_associations':
          return <Associations slice={slice} />;

        case 'what_we_do':
          return <ServicesList slice={slice} />;

        case 'call_to_action':
          return <CallToAction slice={slice} />;

        case 'home_hero':
          return <HomeHero slice={slice} />;

        case 'benefit_card_grid':
          return (
            <BenefitList
              slice={slice.items}
              type="page"
              params={{ filter: slice.primary.bcg_grid_filter }}
              key={`p-${index}`}
            />
          );

        // case 'peba-talk':
        //   return <h1>peba talk</h1>;

        default:
      }
    })();
    return <Fragment key={index}>{res}</Fragment>;
  });

const Page = ({ data, pageContext }) => {
  if (!data) return null;

  const { uid, settings } = pageContext;
  const page = data.prismicPage.data;
  // peba talk posts
  const pebaTalk = data.allPrismicPost.edges;
  const parentClass = page.page_parent ? 'has-parent' : 'no-parent';

  return (
    <main className={`${parentClass} ${uid}`}>
      <ScriptHelemt />
      <GatsbySeo
        title={page.seo_title}
        description={page.seo_description}
        noindex={page.seo_noindex}
        nofollow={page.seo_nofollow}
      />
      <Navbar {...settings.data} />
      <PageSlices slices={page.body} uid={data.prismicPage.uid} />
      {uid === 'peba-talk' && <PebaTalk pebaTalk={pebaTalk} />}
      <PageFooter />
    </main>
  );
};

export default withPreview(Page);
