import React from 'react';
import PostCard from './PostCard';

const PebaTalk = ({ pebaTalk }) => {
  return (
    <>
      <div className="container">
        <div className="row">
          {pebaTalk?.map(({ node }) => {
            // get all the images from the post slice_type image_block
            const images = node.data.body.filter(
              (slice) => slice.slice_type === 'image_block'
            );
            // map the images to an array of urls
            const imageUrls = images.map(
              (image) => image.primary.post_image.url
            );
            //render only for posts with peba-talk
            if (
              !node.data.post_categories.find(
                (cat) => cat.post_category.uid === 'peba-talk'
              )
            )
              return '';
            return (
              <div className="col-12 col-lg-6 p-4" key={node.uid}>
                <PostCard
                  title={node.data.post_title}
                  date={node.data.post_date}
                  description={node.data.post_seo_description}
                  image={imageUrls ? imageUrls[0] : null}
                  link={`/news/peba-talk/${node.uid}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PebaTalk;
