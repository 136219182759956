import React from 'react';
import { Helmet } from 'react-helmet';

export default function ScriptHelemt() {
  return (
    <Helmet>
      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/+i";
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "b89xc6jmuj");`}
      </script>
      {/* <script type="text/javascript">{`console.log("hello World")`}</script> */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-316894-22"
      ></script>
      <script type="text/javascript">
        {` window.dataLayer = window.dataLayer || [];
                 function gtag(){dataLayer.push(arguments);}
                 gtag('js', new Date());

                 gtag('config', 'UA-316894-22');`}
      </script>
    </Helmet>
  );
}
